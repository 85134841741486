<script lang="ts">
  import Setting from "$settingsmenu/Setting.svelte";
  import { _ } from "svelte-i18n";
  import defaultSettings from "../../../../printerdata/defaultSettings.json";
  import NumberSetting from "../NumberSetting.svelte";
  import { supermarkt } from "@printlabels/utils/supermarkt";
  import VisualGridSettings from "./VisualGridSettings.svelte";
  import RulerIcon from "$icons/RulerIcon.svelte";
  import SnapToGrid from "./SnapToGrid.svelte";
  import { userLoaded, userSession } from "$api/user";

  const movesnap = supermarkt(defaultSettings.moveSnap);

  export function reset() {
    userSession.getUserSettings().update((settings) => {
      settings.moveSnap = defaultSettings.moveSnap;
      settings.visualGrid = defaultSettings.visualGrid;
      settings.snapToGrid = defaultSettings.snapToGrid;
      return settings;
    });
  }

  $: if ($userLoaded)
    userSession.getUserSettings().subscribe((settings) => {
      movesnap.set(settings.moveSnap as number);
    });
</script>

<div class="flex flex-col gap-4">
  <div class="flex flex-row justify-between">
    <Setting name="visualGrid" defaultValue={defaultSettings.visualGrid}>
      <VisualGridSettings />
    </Setting>

    <Setting name="snap" defaultValue={defaultSettings.moveSnap}>
      <NumberSetting
        name="snap"
        class="input-primary"
        width="w-32"
        store={movesnap}
        min={0.1}
        updator={(value) =>
          userSession.setUserSettingProperty("moveSnap", value)}
      >
        <RulerIcon slot="icon" size={"2rem"} />
      </NumberSetting>
    </Setting>
  </div>

  <div class="flex flex-row justify-between">
    <Setting name="snapToGrid" defaultValue={defaultSettings.snapToGrid}>
      <SnapToGrid />
    </Setting>
  </div>
</div>
