<script lang="ts">
  import { userLoaded, userSession } from "$api/user";

  let multiSelectKey = "shift";

  $: if ($userLoaded)
    userSession.getUserSettings().subscribe((settings) => {
      multiSelectKey = settings.multiSelect as string;
    });

  function onInput(value: string) {
    console.log(value);
    userSession.setUserSettingProperty("multiSelect", value);
  }
</script>

<div class="flex flex-col gap-4">
  <label class="flex gap-4 cursor-pointer">
    <input
      type="radio"
      bind:group={multiSelectKey}
      value={"shift"}
      on:input={(e) => onInput(e.target.value)}
    />
    <kbd class="kbd kbd-sm">Shift</kbd>
  </label>

  <label class="flex gap-4 cursor-pointer">
    <input
      type="radio"
      bind:group={multiSelectKey}
      value={"ctrl"}
      on:input={(e) => onInput(e.target.value)}
    />
    <kbd class="kbd kbd-sm">Ctrl</kbd>
  </label>
</div>
