<script>
  export let size = "1em";
  export let width = size;
  export let height = size;
  export let color = "currentColor";
  export let viewBox = "0 0 20 21";
  export let ariaLabel = void 0;
  export let ariaHidden = void 0;
  export let title = void 0;
  export let desc = void 0;

  let className = void 0;
  export { className as class };
</script>

<svg
  {viewBox}
  {width}
  {height}
  class={className}
  aria-label={ariaLabel}
  aria-hidden={ariaHidden}
  >{#if desc}<desc>{desc}</desc>{/if}{#if title}<title>{title}</title>{/if}
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15.4933 2.37647C16.9394 2.37647 18.1118 3.54884 18.1118 4.9946C18.1118 6.44069 16.9394 7.61323 15.4933 7.61323C14.0474 7.61323 12.875 6.44086 12.875 4.9946C12.875 3.54884 14.0474 2.37647 15.4933 2.37647ZM17.866 8.52235C16.6879 8.52235 15.7324 9.47791 15.7324 10.6558C15.7324 11.8343 16.6879 12.7895 17.866 12.7898C19.0444 12.7898 20 11.8343 20 10.6558C19.9998 9.47791 19.0444 8.52235 17.866 8.52235ZM15.3921 14.7001C14.5693 14.7001 13.9019 15.3676 13.9019 16.1902C13.9019 17.013 14.5693 17.6804 15.3921 17.6804C16.2147 17.6804 16.8822 17.013 16.8822 16.1902C16.8822 15.3676 16.2147 14.7001 15.3921 14.7001ZM9.69211 17.1114C8.86932 17.1114 8.20187 17.7787 8.20187 18.6015C8.20187 19.4246 8.86932 20.0921 9.69211 20.0921C10.5149 20.0921 11.1822 19.4246 11.1822 18.6015C11.1823 17.7787 10.5151 17.1114 9.69211 17.1114ZM3.86736 14.8566C3.09608 14.8566 2.47098 15.4817 2.47098 16.253C2.47098 17.0239 3.09608 17.6494 3.86736 17.6494C4.63815 17.6494 5.26342 17.0239 5.26342 16.253C5.26342 15.4819 4.63815 14.8566 3.86736 14.8566ZM1.4873 8.97217C0.665816 8.97217 0 9.63799 0 10.4595C0 11.2805 0.665816 11.9463 1.4873 11.9463C2.30828 11.9463 2.9741 11.2805 2.9741 10.4595C2.9741 9.63799 2.30812 8.97217 1.4873 8.97217ZM3.86736 2.78394C2.82841 2.78394 1.98568 3.62684 1.98568 4.66578C1.98568 5.70473 2.82841 6.54714 3.86736 6.54714C4.90631 6.54714 5.74872 5.70473 5.74872 4.66578C5.74872 3.62684 4.90631 2.78394 3.86736 2.78394ZM9.7029 0C8.45188 0 7.43697 1.01459 7.43697 2.26544C7.43697 3.51647 8.45188 4.53105 9.7029 4.53105C10.9539 4.53105 11.9685 3.51647 11.9685 2.26544C11.9685 1.01459 10.9539 0 9.7029 0Z"
    fill={color}
  />
</svg>
