<script lang="ts">
  import { settingsList } from "$settingsmenu/stores/settingslist";
  import { instances } from "$settingsmenu/settings";
  import { _ } from "svelte-i18n";

  function reset() {
    if (!confirm($_("settings.resetAll"))) return;
    $settingsList.forEach((x) => x?.setTo?.(x.defaultValue, instances[x.name]));
  }
</script>

<button class="btn btn-sm hover:btn-error" on:click={reset}
  >{$_("settingitem.name.reset")}</button
>
