<script lang="ts">
  import { userLoaded, userSession } from "$api/user";
  import AutoSaveIcon from "$icons/AutoSaveIcon.svelte";
  import SettingDropdown from "../SettingDropdown.svelte";

  let currentText = "Off";

  async function changeAutoSave(setting: boolean) {
    userSession.setUserSettingProperty("autoSave", setting);
  }

  $: if ($userLoaded)
    userSession.getUserSettings().subscribe((settings) => {
      currentText = settings.autoSave ? "On" : "Off";
    });
</script>

<SettingDropdown {currentText}>
  <AutoSaveIcon slot="icon" />

  <li on:click={() => changeAutoSave(true)} class="hover:bg-base-200">On</li>
  <li on:click={() => changeAutoSave(false)} class="hover:bg-base-200">Off</li>
</SettingDropdown>
