<script lang="ts">
  import { userLoaded, userSession } from "$api/user";
  import DotSpinIcon from "$icons/DotSpinIcon.svelte";
  import SettingDropdown from "../SettingDropdown.svelte";

  let currentText = "No";

  async function changeAnimations(setting: boolean) {
    userSession.setUserSettingProperty("animations", setting);
  }

  $: if ($userLoaded)
    userSession.getUserSettings().subscribe((settings) => {
      currentText = settings.animations ? "Yes" : "No";
    });
</script>

<SettingDropdown {currentText}>
  <DotSpinIcon slot="icon" />

  <li on:click={() => changeAnimations(true)} class="hover:bg-base-200">Yes</li>
  <li on:click={() => changeAnimations(false)} class="hover:bg-base-200">No</li>
</SettingDropdown>
