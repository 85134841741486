<script lang="ts">
  import { blacklistedNames } from "@printlabels/printing/jsprintmanager/blacklistedNames";
  import { printers } from "@printlabels/printing/jsprintmanager/coolPrinter";
  import SettingDropdown from "../SettingDropdown.svelte";
  import PrinterIcon from "svelte-material-icons/PrinterOutline.svelte";
  import { userLoaded, userSession } from "$api/user";

  let currentText = "";

  $: filteredPrinters = ($printers ?? []).filter(
    (x) => !blacklistedNames.includes(x),
  );

  if ($userLoaded)
    userSession.getUserSettings().subscribe((settings) => {
      currentText = settings.printer as string;
    });
</script>

<SettingDropdown
  {currentText}
  width="w-full"
  disabled={!filteredPrinters.length}
>
  <PrinterIcon slot="icon" />
  {#each filteredPrinters as printer}
    <li
      class="hover:bg-base-200"
      on:click={() => userSession.setUserSettingProperty("printer", printer)}
    >
      {printer}
    </li>
  {/each}
</SettingDropdown>
