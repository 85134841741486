<script>
  import MmInput from "$forms/MMInput.svelte";
  import { Units } from "@printlabels/standards/units";

  export let store;
  export let width = "w-24";
  export let labelType = Units.mm;
  export let fixedTo = 2;
</script>

<div
  class="border-base-100 text-base-100 border-[2px] rounded-xl flex overflow-hidden flex-row gap-2 items-center pl-2 pr-2 h-8 {width}"
>
  <slot name="icon" />
  <MmInput
    bind:value={$store}
    {...$$props}
    class="w-full focus:outline-none border-none text-base-100"
    {labelType}
    {fixedTo}
  />
</div>
