<script lang="ts">
  import { userLoaded, userSession } from "$api/user";
  import SettingDropdown from "../SettingDropdown.svelte";
  import WebIcon from "svelte-material-icons/Web.svelte";

  let lang = "English";

  async function changeLanguage(lang: string) {
    await userSession.updateUserLanguage(lang);
  }

  const languages = {
    en: "English",
    nl: "Nederlands",
  };

  $: if ($userLoaded)
    userSession.getUser().subscribe((user) => {
      lang = languages[user.lang];
    });
</script>

<SettingDropdown currentText={lang} width="w-36">
  <WebIcon slot="icon" />

  {#each Object.entries(languages) as [key, value]}
    <li on:click={() => changeLanguage(key)} class="hover:bg-base-200">
      {value}
    </li>
  {/each}
</SettingDropdown>
