import AccountOutline from "svelte-material-icons/AccountOutline.svelte";
import CardBulletedOutline from "svelte-material-icons/CardBulletedOutline.svelte";
import CogOutline from "svelte-material-icons/CogOutline.svelte";
import KeyboardOutline from "svelte-material-icons/KeyboardOutline.svelte";
import Speedometer from "svelte-material-icons/Speedometer.svelte";
import { writable, type Writable } from "svelte/store";

export type SettingTab = {
  icon: ConstructorOfATypedSvelteComponent;
  name: string;
  id: string;
  line?: boolean;
};

export const settingTabs: Writable<SettingTab[]> = writable([
  {
    icon: CogOutline,
    name: "general",
    id: "general",
  },
  {
    icon: KeyboardOutline,
    name: "keyboard",
    id: "keyboard",
  },
  {
    icon: CardBulletedOutline,
    name: "label",
    id: "labels",
  },
  {
    icon: Speedometer,
    name: "performance",
    id: "performance",
    line: true,
  },
  {
    icon: AccountOutline,
    name: "account",
    id: "account",
  },
]);
