<script lang="ts">
  import SettingDropdown from "../SettingDropdown.svelte";
  import GridIcon from "svelte-material-icons/Grid.svelte";
  import { userLoaded, userSession } from "$api/user";

  let currentText = "No";

  function changeVisualGrid(setting: string) {
    userSession.setUserSettingProperty("visualGrid", setting);
  }

  $: if ($userLoaded)
    userSession.getUserSettings().subscribe((settings) => {
      currentText = settings.visualGrid === "lines" ? "Yes" : "No";
    });
</script>

<SettingDropdown {currentText}>
  <GridIcon slot="icon" />

  <li on:click={() => changeVisualGrid("lines")} class="hover:bg-base-200">
    Yes
  </li>
  <li on:click={() => changeVisualGrid("off")} class="hover:bg-base-200">No</li>
</SettingDropdown>
