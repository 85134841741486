<script lang="ts">
  import Dropdown from "$utils/Dropdown.svelte";
  import RotatableArrow from "$utils/RotatableArrow.svelte";

  export let currentText: string;
  export let width = "w-24";

  let open = false;
</script>

<Dropdown
  bind:modalOpen={open}
  containerClasses={width}
  dropdownClasses="flex flex-col gap-2 bg-white pt-2 pb-2 min-w-full rounded-lg [&>li]:whitespace-nowrap text-[1rem] z-[9999] shadow-lg [&>li]:pl-2 [&>li]:pr-2"
>
  <div
    slot="outside"
    class="rounded-xl border-[2px] border-base-100 flex flex-row items-center pl-2 pr-2 h-8 {width} justify-between"
  >
    <div class="flex flex-row flex-nowrap gap-2 items-center text-base-100">
      <slot name="icon" />
      <p class="whitespace-nowrap">{currentText}</p>
    </div>
    <div>
      <RotatableArrow
        rotated={open}
        falseState="90deg"
        trueState="270deg"
        iconSize="0.8em"
      />
    </div>
  </div>

  <slot />
</Dropdown>
