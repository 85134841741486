<script lang="ts">
  import SnapToGridIcon from "$icons/SnapToGridIcon.svelte";
  import SettingDropdown from "../SettingDropdown.svelte";
  import { userLoaded, userSession } from "$api/user";

  let currentText = "No";

  function changeSnapToGrid(setting: boolean) {
    userSession.setUserSettingProperty("snapToGrid", setting);
  }

  $: if ($userLoaded)
    userSession.getUserSettings().subscribe((settings) => {
      currentText = settings.snapToGrid ? "Yes" : "No";
    });
</script>

<SettingDropdown {currentText}>
  <SnapToGridIcon slot="icon" />
  <li on:click={() => changeSnapToGrid(true)} class="hover:bg-base-200">Yes</li>
  <li on:click={() => changeSnapToGrid(false)} class="hover:bg-base-200">No</li>
</SettingDropdown>
