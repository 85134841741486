<script context="module" lang="ts">
  import { settingTabs } from "./stores/settingtabs";
  import Setting from "./Setting.svelte";
  import { settingsList } from "./stores/settingslist";
  import { addDefaultSettings } from "./settings";
  import { _ } from "svelte-i18n";
  import Reset from "svelte-material-icons/Refresh.svelte";
  import { instances } from "./settings";
  import UserIcon from "$header/UserIcon.svelte";
  import SoftPopup from "$popups/SoftPopup.svelte";
  import { userLoaded, userSession } from "$api/user";
</script>

<script lang="ts">
  let username = "";

  $: if ($userLoaded) {
    addDefaultSettings();

    userSession.getUser().subscribe((user) => (username = user.name));
  }
</script>

<SoftPopup
  id="settingsMenu"
  class1="min-h-[80%]"
  class2="w-[40vw] max-w-[70vw] h-[80vh] max-h-[80vh]"
  class3="divide-x flex flex-row gap-6"
  title={"modals.settings"}
>
  <div class="w-56 h-full flex flex-col relative gap-6">
    <div>
      <UserIcon {username} />
    </div>

    <div
      class="w-full h-fit flex flex-col bg-base-100/10 rounded-xl pt-4 pb-2 gap-2"
    >
      {#each $settingTabs as { icon, name, id, line } (id)}
        <div
          class="flex gap-2 items-center text-base-content hover:bg-primary/10 w-full pl-8"
          on:click={() => document.getElementById(id).scrollIntoView()}
        >
          <svelte:component this={icon} />
          <span>{$_(`settings.${name}`)}</span>
        </div>

        {#if line}
          <div class="bg-base-100/5 w-full h-[2px]" />
        {/if}
      {/each}
    </div>

    <img
      src="/static/branding/PrintLabels online_logo.svg"
      alt="logo"
      class="absolute bottom-0 w-full"
    />
  </div>
  <div
    class="w-full h-full max-h-[80vh] flex flex-col pl-5 relative overflow-y-scroll fancy-scrollbar scroll-smooth break-all"
  >
    {#each $settingTabs as { icon, name, id } (id)}
      <div {id} class="flex flex-col w-full mb-10">
        <div class="flex gap-2 items-center text-base-100 text-lg">
          <svelte:component this={icon} />
          <span>{$_(`settings.${name}`)}</span>
          <p
            on:click={() => {
              if (confirm($_("settings.reset"))) {
                $settingsList
                  .filter((x) => x.parent == id)
                  .forEach((x) =>
                    x?.setTo?.(x.defaultValue, instances[x.name]),
                  );
              }
            }}
          >
            <Reset />
          </p>
        </div>
        <div class="bg-base-100/80 w-full h-[2px]" />
        <div class="flex flex-col gap-6 pr-6 pl-2">
          {#each $settingsList as setting (setting.name)}
            {#if setting.parent == id}
              <Setting
                name={setting.name}
                defaultValue={setting.defaultValue}
                setTo={setting.setTo}
                instance={instances[setting.name]}
              >
                <svelte:component
                  this={setting.component}
                  bind:this={instances[setting.name]}
                  {...setting.props}
                />
              </Setting>
            {/if}
          {/each}
        </div>
      </div>
    {/each}
  </div>
</SoftPopup>
