<script>
  export let size = "1em";
  export let width = size;
  export let height = size;
  export let color = "currentColor";
  export let viewBox = "0 0 20 20";
  export let ariaLabel = void 0;
  export let ariaHidden = void 0;
  export let title = void 0;
  export let desc = void 0;

  let className = void 0;
  export { className as class };
</script>

<svg
  {viewBox}
  {width}
  {height}
  class={className}
  aria-label={ariaLabel}
  aria-hidden={ariaHidden}
  >{#if desc}<desc>{desc}</desc>{/if}{#if title}<title>{title}</title>{/if}
  <path
    d="M0.149727 5.34136L5.34136 0.149727C5.44227 0.0488242 5.57246 0 5.70429 0C5.83449 0 5.96631 0.0488242 6.06559 0.149727L19.8486 13.9328C19.9479 14.0321 19.9984 14.1639 19.9984 14.2957C19.9984 14.4259 19.9479 14.5561 19.8486 14.6554L19.847 14.657L19.8486 14.6586L14.657 19.8503C14.5561 19.9512 14.4259 20 14.2941 20C14.1639 20 14.0321 19.9512 13.9312 19.8503L0.149727 6.06559C0.0488242 5.96468 0 5.83286 0 5.70429C0 5.57246 0.0488242 5.44227 0.149727 5.34136ZM17.1405 12.5559L14.784 14.9125C14.6 15.0964 14.3218 15.1176 14.1639 14.9597C14.006 14.8019 14.0272 14.5236 14.2111 14.3397L16.5677 11.9831L14.6668 10.0806L13.5503 11.1954C13.3664 11.3793 13.0881 11.4004 12.9303 11.2426C12.7724 11.0847 12.7936 10.8064 12.9775 10.6225L14.0939 9.50606L12.3493 7.76467L9.99268 10.1212C9.80877 10.3052 9.53047 10.3263 9.37261 10.1684C9.21474 10.0106 9.2359 9.73228 9.41981 9.54838L11.7764 7.1918L9.94222 5.35601L8.82578 6.47083C8.64188 6.65473 8.36358 6.67589 8.20571 6.51802C8.04785 6.36016 8.06901 6.08186 8.25291 5.89796L9.36936 4.78151L7.55635 2.97176L5.19977 5.32834C5.01587 5.51225 4.7392 5.53178 4.58133 5.37554C4.42347 5.21767 4.44463 4.941 4.62853 4.7571L6.98511 2.40052L5.70429 1.1197L1.11807 5.70429L14.2941 18.8803L18.8787 14.2957C16.7304 12.1442 19.2904 14.7058 17.1405 12.5559Z"
    fill={color}
  />
</svg>
