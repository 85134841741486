<script context="module">
  import Refresh from "svelte-material-icons/Refresh.svelte";
  import { _ } from "svelte-i18n";
</script>

<script lang="ts">
  export let name: string | undefined;
  export let defaultValue: unknown = undefined;
  export let instance: any = undefined;
  export let setTo: (variable: unknown, instance: unknown) => void = () => {
    return;
  };
</script>

<div class="mt-2 flex flex-col gap-1 text-base-100" id="settings-{name}">
  <div class="flex flex-row gap-1 items-center">
    {#if name != undefined}
      <h1>{$_(`settingitem.name.${name}`, { default: name })}</h1>
      {#if defaultValue != undefined}
        <div
          on:click={() => setTo(defaultValue, instance)}
          class="cursor-pointer"
        >
          <Refresh />
        </div>
      {/if}
    {/if}
  </div>
  <slot />
</div>
