import FactoryReset from "./components/onehitwondersconfig/FactoryReset.svelte";
import type Icon from "svelte-material-icons/EmailOutline.svelte";
import MultiSelect from "./components/onehitwondersconfig/MultiSelect.svelte";
import Snapping from "./components/onehitwondersconfig/Snapping.svelte";
import type { Supermarkt } from "@printlabels/utils/supermarkt";
import defaultSettings from "../../printerdata/defaultSettings.json";
import { settingTabs } from "./stores/settingtabs";
import { settingsList } from "./stores/settingslist";
import { transformed } from "$utils/transformed";
import Language from "./components/onehitwondersconfig/Language.svelte";
import Logout from "../Logout.svelte";
import NumberSetting from "./components/NumberSetting.svelte";
import { Units } from "@printlabels/standards/units";
import AutoSave from "./components/onehitwondersconfig/AutoSave.svelte";
import Animations from "./components/onehitwondersconfig/Animations.svelte";
import Printer from "./components/onehitwondersconfig/Printer.svelte";
import { userSession } from "$api/user";

export const instances: Record<string, unknown> = {};

type ConstructorOfATypedSvelteComponent = typeof Icon;

export type Settings = {
  component: ConstructorOfATypedSvelteComponent;
  name: string | undefined;
  defaultValue?: unknown;
  setTo?: (variable: unknown, instance: unknown) => void;
  parent: string;
  props: Record<string, unknown>;
};

export function getW<T>(setting: string): Supermarkt<T> {
  return transformed(userSession.getUserSettings(), {
    in(x) {
      return x[setting] ?? defaultSettings[setting];
    },
    out(x) {
      let val;
      userSession.getUserSettings().update((v) => {
        v[setting] = x;
        val = v;
        return x;
      });
      return val;
    },
  });
}

export function createSettingTab(icon: typeof Icon, name: string, id: string) {
  settingTabs.update((tabs) => {
    tabs.push({ icon, name, id });
    return tabs;
  });
}

export function addSetting<T>(
  settings: Omit<Settings, "component"> & { component: T },
) {
  settingsList.update((list) => {
    list.push({
      ...settings,
      component:
        settings.component as unknown as ConstructorOfATypedSvelteComponent,
    });
    return list;
  });
}

export function addDefaultSettings() {
  settingsList.update((list) => {
    list.push(
      {
        component: Language as ConstructorOfATypedSvelteComponent,
        name: "language",
        parent: "general",
        props: {},
      },
      {
        component: Printer as ConstructorOfATypedSvelteComponent,
        name: "printer",
        parent: "general",
        defaultValue: defaultSettings.printer,
        props: {},
      },
      {
        component: AutoSave,
        name: "autoSave",
        parent: "general",
        defaultValue: defaultSettings.autoSave,
        props: {},
      },
      {
        component: Animations,
        name: "labelanimation",
        parent: "performance",
        defaultValue: defaultSettings.animations,
        props: {},
      },
      {
        component: Logout,
        name: undefined,
        parent: "account",
        props: {},
      },
      {
        component: Snapping as ConstructorOfATypedSvelteComponent,
        name: "",
        parent: "labels",
        defaultValue: null,
        setTo: (_, instance) => instance.reset(),
        props: {},
      },
      {
        component: NumberSetting,
        name: "dpi",
        defaultValue: defaultSettings.dpi,
        setTo: (value: unknown) => getW("dpi").set(value),
        parent: "labels",
        props: {
          store: getW("dpi"),
          labelType: Units.dpi,
          fixedTo: 0,
        },
      },
      {
        component: MultiSelect,
        name: "multiSelect",
        defaultValue: defaultSettings.multiSelect,
        setTo: (value: unknown) => getW("multiSelect").set(value),
        parent: "keyboard",
        props: {},
      },
      {
        component: FactoryReset,
        name: "factoryReset",
        parent: "debug",
        props: {},
      },
    );
    return list;
  });
}
